export type Colors = {
	primary: string;
	background: string;
	white: string;
	white1: string;
	white2: string;
	white3: string;
	white4: string;
	white5: string;
	black016: string;
	black008: string;
	black: string;
	black1: string;
	black2: string;
	black3: string;
	black50: string;
	black500: string;
	error: string;
	error50: string;
	error200: string;
	errorPrimary: string;
	errorSecondary: string;
	successPrimary: string;
	successSecondary: string;
	border: string;
	yellow: string;
	green: string;
	grey: string;
	grey200: string;
	green2: string;
	yellow700: string;
	orange: string;
	aqua100: string;
	blue500: string;
	contentBackground: string;
	dark: string;
	warning50: string;
	warning200: string;
	warning700: string;
};

const colors: Colors = {
	primary: "#038767",
	background: "#fafafa",
	white: "#ffffff",
	white1: "#f0f0f0",
	white2: "#f0f2f5",
	white3: "#dddddd",
	white4: "#f3f9f7",
	white5: "#efefef",
	black016: "rgba(0, 0, 0, 0.16)",
	black008: "rgba(0, 0, 0, 0.08)",
	black: "#1d1d1d",
	black1: "#777777",
	black2: "#4a4a4a",
	black3: "#a5a5a5",
	black50: "#f7f7f7",
	black500: "#a3a3a3",
	error: "#d44140",
	error50: "#fff1f2",
	error200: "#fecdd3",
	errorPrimary: "#f6d9d9",
	errorSecondary: "#aa3433",
	successPrimary: "#cde7e1",
	successSecondary: "#026c52",
	warning50: "#fffbeb",
	warning700: "#d97706",
	warning200: "#fde68a",
	border: "#d2d2d2",
	yellow: "#fff6cc",
	green: "#68B7a4",
	grey: "#d2d2d2",
	grey200: "#e5e5e5",
	green2: "#359f85",
	yellow700: "#997e00",
	orange: "#f49342",
	aqua100: "#dbf4ec",
	blue500: "#5dc7e3",
	contentBackground: "#f6f6f6",
	dark: "#000000"
};

export default colors;
