import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import { Header, Sidebar } from "../Annotator";

const StyledLayout = styled(Layout)`
	min-height: 100%;

	.ant-menu-item {
		border-radius: 0;
		width: 100%;
	}

	.ant-menu-item-selected {
		box-shadow: ${(props) => `inset 3px 0 ${props.theme.color.primary}`};
	}
`;

const StyledContent = styled(Layout.Content)`
	padding: 24px;

	@media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
		padding: 16px;
	}
`;

function LayoutAnnotator() {
	return (
		<StyledLayout>
			<Header />

			<Layout>
				<Sidebar />
				<StyledContent>
					<Outlet />
				</StyledContent>
			</Layout>
		</StyledLayout>
	);
}

export default LayoutAnnotator;
