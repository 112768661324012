import { ArrowLeftOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import styled from "styled-components";

import { Sidebar } from "../Annotator";

const StyledMenu = styled(Menu)`
	border: 0 !important;
	color: #ffffff;
`;

const StyledHeader = styled(Layout.Header)`
	align-items: center;
	display: flex;
	padding: 0 0px;
	boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)
`;

function HeaderAnnotator() {
	return (
		<StyledHeader>
			<Sidebar.Drawer />

			<StyledMenu
				items={[
					{
						key: "image_file_name",
						icon: <ArrowLeftOutlined />,
						label: "10-11-vibrio.jpg"
					}
				]}
				theme={theme}
			/>
		</StyledHeader>
	);
}

export default HeaderAnnotator;
