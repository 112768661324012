import { Input } from "antd";
import { ComponentType, forwardRef } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import { Form } from "../components";

interface InputNumberProps extends NumericFormatProps {
	error?: string;
	label?: string;
	status?: string;
}

const InputNumber = forwardRef<unknown, InputNumberProps>(
	(
		{
			allowNegative = false,
			decimalSeparator = ",",
			placeholder = "0",
			thousandSeparator = ".",
			error,
			label,
			...props
		},
		ref
	) => (
		<>
			{!!label && <Form.Label htmlFor={props.id}>{label}</Form.Label>}

			<NumericFormat<InputNumberProps>
				{...props}
				allowNegative={allowNegative}
				customInput={Input as ComponentType}
				decimalSeparator={decimalSeparator}
				getInputRef={ref}
				inputMode="decimal"
				placeholder={placeholder}
				status={error ? "error" : ""}
				thousandSeparator={thousandSeparator}
			/>

			{!!error && <Form.Text type="danger">{error}</Form.Text>}
		</>
	)
);

InputNumber.displayName = "InputNumber";

export default InputNumber;
