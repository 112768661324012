import { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface ListProps {
  dense?: boolean;
}

const StyledListItem = styled.li`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  &:not(:last-child) {
    padding-bottom: 2px;
  }

  &::before {
    content: '•';
    font-weight: 600;
    padding: 0 8px;
  }
`;

const StyledList = styled.ul<ListProps>`
  margin: 0;
  list-style: none;
  padding: 0;

  ${StyledListItem} {
    padding: ${({ dense }) => (dense ? '0' : '8px 0')};
  }
`;

function List({
  children,
  dense = true,
  ...props
}: PropsWithChildren<ListProps>) {
  return (
    <StyledList dense={dense} {...props}>
      {children}
    </StyledList>
  );
}

function ListItem({ children, ...props }: PropsWithChildren) {
  return <StyledListItem {...props}>{children}</StyledListItem>;
}

export default Object.assign(List, {
  Item: ListItem,
});
