import styled from "styled-components";

import { Loader } from "../components";

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
`;

function SuspenseFallback() {
	return (
		<Wrapper>
			<Loader />
		</Wrapper>
	);
}

export default SuspenseFallback;
