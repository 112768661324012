import { DefaultTheme } from "styled-components";

import colors from "./colors";
import breakpoints from "./breakpoints";

export { default as antDTheme } from "./ant-theme";

export const styledComponentTheme: DefaultTheme = {
	color: colors,
	breakpoint: breakpoints
};
