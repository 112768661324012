import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Layout } from "../components";
import { LayoutAnnotator } from "../components/Annotator";

const PageLogin = lazy(() => import("../pages/auth/login"));
const PageDasboard = lazy(() => import("../pages/dashboard"));
const PageDiseaseInsight = lazy(() => import("../pages/disease-insight"));
const PageFAQ = lazy(() => import("../pages/faq"));
const PageUserHistory = lazy(() => import("../pages/user-history"));
const PageEditResult = lazy(() => import("../pages/user-history/edit-result"));
const PageAnnotator = lazy(() => import("../pages/user-history/annotator"));

function Routers() {
	return (
		<Routes>
			<Route path="/" element={<Navigate to="/login" />} />

			<Route path="/login" element={<PageLogin />} />

			<Route path="/" element={<Layout />}>
				<Route path="/dashboard" element={<PageDasboard />} />
				<Route
					path="/disease-insight"
					element={<PageDiseaseInsight />}
				/>
				<Route path="/faq" element={<PageFAQ />} />
				<Route path="/user-history" element={<PageUserHistory />} />
				<Route path="/edit-result" element={<PageEditResult />} />
			</Route>

			<Route path="/" element={<LayoutAnnotator />}>
				<Route path="/annotator" element={<PageAnnotator />} />
			</Route>
		</Routes>
	);
}

export default Routers;
