import { Layout } from "antd";

import { MenuNavigation } from "../index";

import SidebarDrawer from "./SidebarDrawer";

function Sidebar() {
	return (
		<Layout.Sider
			collapsible
			width={250}
			trigger={null}
			breakpoint="lg"
			collapsedWidth="0">
			<MenuNavigation />
		</Layout.Sider>
	);
}

export default Object.assign(Sidebar, {
	Drawer: SidebarDrawer
});
