import { Layout } from "antd";
import styled from "styled-components";

import { MenuNavigation } from "../../components";

import SidebarDrawer from "./SidebarDrawer";

const SidebarBrand = styled.div`
	align-items: center;
	display: flex;
	height: 64px;

	.ant-typography {
		margin: 0;
	}

	.sidebar__logo {
		height: 24px;
		margin: 16px auto;
	}
`;

function Sidebar() {
	return (
		<Layout.Sider
			collapsible
			theme="light"
			width={250}
			trigger={null}
			breakpoint="lg"
			collapsedWidth="0">
			<SidebarBrand>
				<img
					className="sidebar__logo"
					src="/vibriohub.png"
					alt="eFishery"
					loading="lazy"
				/>
			</SidebarBrand>

			<MenuNavigation />
		</Layout.Sider>
	);
}

export default Object.assign(Sidebar, {
	Drawer: SidebarDrawer
});
