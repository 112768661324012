import {
	ExperimentOutlined,
	HistoryOutlined,
	BarChartOutlined,
	SolutionOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function MenuNavigation() {
	const navigate = useNavigate();
	const location = useLocation();

	const selectedKey = useMemo(() => {
		const pathnames = location.pathname.split("/");
		const validPathnames = pathnames.splice(0, 2);

		return validPathnames.join("/");
	}, [location.pathname]);

	return (
		<Menu
			mode="inline"
			theme="light"
			defaultSelectedKeys={["/"]}
			selectedKeys={[selectedKey]}
			items={[
				{
					key: "/dashboard",
					label: "Dashboard",
					icon: <BarChartOutlined />
				},
				{
					key: "/user-history",
					label: "User History",
					icon: <HistoryOutlined />
				},
				{
					key: "/disease-insight",
					label: "Disease Insight",
					icon: <ExperimentOutlined />
				},
				{
					key: "/faq",
					label: "FAQ",
					icon: <SolutionOutlined />
				}
			]}
			onClick={(menu) => navigate(menu.key)}
		/>
	);
}

export default MenuNavigation;
