import { MenuOutlined } from "@ant-design/icons";
import { Button, Drawer, Grid, theme } from "antd";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { MenuNavigation } from "../../../components";

const StyledDrawer = styled(Drawer)`
	.ant-drawer-body {
		padding: 0;
	}

	.ant-menu-item {
		border-radius: 0;
		margin: 4px !important;
	}
`;

function SidebarDrawer() {
	const screens = Grid.useBreakpoint();

	const isMenuButtonShown = useMemo(() => {
		const largeScreens = [screens.lg, screens.xl, screens.xxl];
		return !largeScreens.includes(true);
	}, [screens.lg, screens.xl, screens.xxl]);

	const [isOpen, setOpenStatus] = useState(false);

	return (
		<>
			{isMenuButtonShown && (
				<Button
					icon={<MenuOutlined />}
					shape="circle"
					type="text"
					onClick={() => setOpenStatus(true)}
				/>
			)}

			{!isMenuButtonShown && <div>&nbsp;</div>}

			<StyledDrawer
				closable={false}
				closeIcon={false}
				open={isOpen}
				placement="left"
				width={250}
				theme={theme}
				onClose={() => setOpenStatus(false)}>
				<MenuNavigation />
			</StyledDrawer>
		</>
	);
}

export default SidebarDrawer;
